import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const params = Object.assign(props)
  if (
    proxy.$Util.isEmpty(params.userId) ||
    proxy.$Util.isEmpty(params.authValue)
  ) {
    router.go(-1)
  }
  const state = reactive({
    userId: params.userId,
    authValue: params.authValue,
    password: '',
    confirmPw: ''
  })
  const fnNext = async () => {
    if (!isValid()) return
    const params = {}
    params.userId = state.userId
    params.authValue = state.authValue
    params.password = state.password
    const res = await proxy.$UserSvc.postChangePassword(params)
    if (res.resultCode === '0000') {
      await router.replace({
        name: 'find-password-done-index',
        params: { userId: state.userId }
      })
    } else {
      alert(res.resultMsg)
    }
  }
  const isValid = () => {
    if (!proxy.$Util.isValidPassword(state.password)) {
      alert(
        '영문, 숫자, 특수문자를 모두 포함하여 8자~12자 이내로 입력해주세요.'
      )
      return false
    }
    if (state.password !== state.confirmPw) {
      alert('비밀번호가 일치하지 않습니다. 비밀번호를 다시 입력해주세요.')
      return false
    }
    return true
  }
  return { ...toRefs(state), fnNext }
}
