<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>비밀번호 재설정</span>
          </div>
          <div class="inform center">
            <div>
              <span class="color">{{ userId }}</span
              >님의<br />
              비밀번호를 재설정 해주세요.<br />
              <br />
            </div>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">비밀번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 입력해 주세요."
                        v-model="password"
                      />
                    </label>
                    <div class="hint">
                      영문, 숫자, 특수문자를 모두 포함하여 8자~12자 이내로
                      입력해 주세요.
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">비밀번호 확인</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 한번 더 입력해 주세요."
                        v-model="confirmPw"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnNext">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'find-password-form-index',
  props: {
    userId: {
      type: [String],
      default: ''
    },
    authValue: {
      type: [String],
      default: ''
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
